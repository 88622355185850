/* original: #787878 */
/* original: 12px */
/* original: 200 */
html, body {
  height: 100%; }

body {
  font-family: 'Titillium Web', Arial, sans-serif;
  font-weight: 300;
  color: #4e5555;
  background-color: #717878; }

a, a:hover, a:visited {
  color: #4e5555;
  text-decoration: underline; }

.site-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%; }

.site-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  max-width: 1170px;
  max-height: 710px; }

.container {
  width: 1170px !important;
  padding: 0; }

.row {
  margin: 0; }

aside {
  float: left;
  width: 64px;
  padding-top: 230px; }

aside img {
  margin-bottom: 8px; }

.main {
  float: right;
  width: 1106px;
  background-color: #f7f2f1; }

.content-1034 {
  width: 1034px;
  margin: 0 auto; }

header {
  height: 146px; }

header img.logo {
  padding-top: 30px;
  padding-left: 15px; }

header ul.links {
  list-style: none;
  padding-top: 16px;
  padding-left: 4px; }

header ul.links li {
  float: left; }

header ul.links li a {
  font-size: 13px;
  font-weight: 600;
  color: #e41a2b;
  text-transform: uppercase; }

header div.slogan {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 600;
  line-height: 34px;
  text-align: right;
  margin-right: 20px;
  padding-top: 12px; }

header div.phone-contact {
  float: right;
  width: 358px;
  margin-top: 94px; }

header div.phone-contact p.phone {
  display: inline;
  font-size: 22px;
  font-weight: 600; }

header div.phone-contact p.phone:before {
  content: '\2706';
  font-size: 30px;
  margin-right: 10px; }

.mainnav {
  position: relative;
  z-index: 1; }

.nav > li > a {
  padding: 10px 5px;
  text-decoration: none; }

.mainnav .nav {
  background-color: #717878;
  border-color: #717878;
  border-radius: 10px;
  height: 32px;
  margin-bottom: 20px; }

.mainnav .nav-justified > li > a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 6px 0px; }

.mainnav .nav-justified > li > a {
  border-left: 1px solid #c4c8c8;
  border-right: 1px solid #a3a7a7; }

.mainnav .nav-justified > li:first-child > a {
  border-left: none;
  border-right: 1px solid #a3a7a7;
  padding-left: 16px; }

.mainnav .nav-justified > li:last-child > a {
  border-left: 1px solid #c4c8c8;
  border-right: none;
  padding-right: 20px; }

.nav > li > a:hover, .nav > li > a:focus, .mainnav .nav-justified > li.active a {
  background-color: transparent;
  color: #e41a2b; }

footer {
  position: relative;
  background-color: #717878;
  border-color: #717878;
  border-radius: 10px;
  height: 32px;
  margin-bottom: 30px;
  padding-left: 16px;
  padding-right: 20px;
  z-index: 1; }

footer p {
  color: #afb3b3;
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 33px;
  margin-left: 2px; }

footer p.bold {
  color: #fff;
  font-size: 13px;
  font-weight: 400; }

.main-content {
  min-height: 450px; }

.main-content p, .main-content a {
  font-size: 13px;
  line-height: 18px; }

.backgroundimage {
  margin-top: -30px;
  margin-bottom: -30px;
  height: 500px; }

.backgroundimage .page-header {
  margin: 58px 0 0px;
  border: none;
  min-height: 65px; }

.bg-index {
  background: url(/img/bg-index.png) 120px 200px no-repeat;
  min-height: 450px;
  border: none; }

.bg-unterhaltungselektronik {
  background: url(/img/bg-unterhaltungselektronik.jpg) top right no-repeat;
  border: none; }

.bg-antennenanlagen {
  background: url(/img/bg-antennenanlagen.jpg) top right no-repeat;
  border: none; }

.bg-telekommunikation {
  background: url(/img/bg-telekommunikation.jpg) top right no-repeat;
  border: none; }

.bg-pc-netzwerke {
  background: url(/img/bg-pc-netzwerke.jpg) top right no-repeat;
  border: none; }

.bg-haushaltsgeraete {
  background: url(/img/bg-haushaltsgeraete.jpg) top right no-repeat;
  border: none; }

.bg-einbruchmeldeanlagen {
  background: url(/img/bg-einbruchmeldeanlagen.jpg) top right no-repeat;
  border: none; }

.bg-videoueberwachung {
  background: url(/img/bg-videoueberwachung.jpg) top right no-repeat;
  border: none; }

.page-header {
  margin: 28px 0 14px;
  border: none; }

.page-header h1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  width: 450px; }

.image-50 {
  float: left;
  width: 250px;
  height: 368px;
  margin-left: -51px;
  margin-top: 32px; }

.content-50 {
  float: left;
  width: 300px;
  background-color: #fff;
  /* padding: 20px 25px 10px 25px; # für font-size: 12px*/
  padding: 10px 15px 5px 15px;
  margin-top: 32px; }

.content-50 h1, .content-50 span.big {
  font-size: 13px;
  font-weight: 400; }

.content-50 h1 {
  margin-top: 0px;
  line-height: 18px; }

.content-service {
  padding: 20px 25px 10px 60px;
  height: 368px; }

.content-service ul {
  margin: 30px 0;
  padding: 0 1em;
  list-style: none; }

.content-service ul li {
  line-height: 16px; }

.content-service ul li:before {
  content: "";
  border-color: transparent #e41818;
  border-style: solid;
  border-width: 0.3em 0 0.3em 0.4em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative; }

.content-kontakt {
  width: 550px;
  height: 368px;
  background-color: #fff;
  padding: 20px 25px 10px 45px;
  margin-top: 32px;
  margin-left: -68px; }

.content-kontakt h1 {
  font-size: 18px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px; }

.impressum {
  margin-bottom: 30px; }

.impressum .page-header {
  margin: 28px 0 0px; }

.impressum h2 {
  font-size: 13px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 5px; }

.backgroundimage-content-50 {
  float: left;
  width: 460px;
  text-align: justify; }

.kontakt label {
  width: 100px;
  font-weight: 300;
  font-size: 13px;
  vertical-align: top;
  padding-top: 6px; }

.kontakt .form-group {
  margin-bottom: 10px; }

.kontakt .form-control {
  display: inline;
  width: 76%;
  padding: 6px 6px;
  height: 30px; }

.kontakt input.fc-email {
  width: 76%;
  display: inline; }

.kontakt input, .kontakt textarea {
  font-size: 13px; }

.kontakt textarea.form-control {
  width: 76%;
  height: 50px; }

.kontakt .checkbox {
  display: inline; }

.kontakt .checkbox label {
  width: auto;
  padding-left: 30px; }

.kontakt p {
  font-size: 13px;
  font-weight: 300; }

.kontakt .btn {
  float: right;
  font-size: 13px;
  color: #fff;
  background-color: #e41a2b;
  font-weight: 300;
  text-transform: uppercase;
  padding: 6px;
  border-radius: 10px;
  margin: 0px;
  margin-right: 10px; }

.kontakt .btn:focus {
  outline: 0; }

.kontakt button:hover {
  background-color: #9d1422; }

.kontakt p.form-error {
  font-size: 13px;
  color: #e41a2b;
  font-weight: 400;
  text-transform: none;
  /* margin-top: 2px; */
  margin-left: 110px; }

.carousel {
  margin-top: 30px; }

.carousel .owl-item {
  text-align: center; }

.carousel .owl-wrapper-outer {
  background-color: rgba(128, 128, 128, 0.2); }

a.btn-video {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 2px;
  background-color: #e32119;
  padding: 8px 10px 8px 10px;
  border-radius: 10px;
  top: -82px;
  left: 490px; }

a.btn-video {
  outline: 0; }

.video .page-header {
  padding-top: 90px; }

.monitor {
  position: absolute;
  top: 28px;
  left: 36px; }

.icon {
  float: left;
  margin-top: -16px;
  margin-right: 15px; }

.imagemap {
  margin-top: 10px; }

p.bold {
  font-size: 13px;
  font-weight: 400; }

.red {
  color: #e41a2b; }

.email {
  direction: rtl;
  unicode-bidi: bidi-override; }

.upper {
  text-transform: uppercase; }

.nopadding-left {
  padding-left: 0; }

.nopadding-right {
  padding-right: 0; }

.padding-left-32 {
  padding-left: 32px; }
